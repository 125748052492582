import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO;

// 设置-清关公司-新增or修改清关公司
export function postClearanceSave(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/clearance/save`, params);
}

// 统计-流程耗时汇总
export function getStatSummary(params = {}) {
  return axios.get(`${BASE_URL}/api/bi/entry/stat/summary`, {params});
}

// 统计-流程耗时个人
export function getStatPerson(params = {}) {
  return axios.get(`${BASE_URL}/api/bi/entry/stat/person`, {params});
}

// 全局-获取柜融资情况
export function getFinanceStat(params = {}) {
  return axios.get(`${BASE_URL}/api/common/cabinet/finance/stat`, {params});
}

// 设置-清关公司-保存报价
export function postClearanceSaveQuotation(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/clearance/save/quotation`, params);
}

// 设置-清关公司-报价列表
export function getClearanceListQuotation(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/clearance/list/quotation`, {params});
}

// 设置-供货商-详情
export function getSupplierInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/supplier/info`, {params});
}

// 设置-供货商-列表
export function getSupplierList(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/supplier/list`, {params});
}

// 设置-供货商-保存
export function postSupplierSave(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/supplier/save`, params);
}

// 设置-供货商-银行保存
export function postSupplierBankSave(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/supplier/bank/save`, params);
}

// 设置-国家
export function getCountriesSelect(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/common/countries/select`, {params});
}

// 设置-币别
export function getSupplierCoinType(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/supplier/get/coinType`, { params });
}

// 设置-主体信息-添加银行
export function postEnterpriseBankSave(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/enterprise/bank/save`, params);
}

// 获取有效合作模型
export function getCoopreationModeList(params = {}) {
  return axios.get(`${BASE_URL}/api/crm/buyer/effective/coopreation/mode/list`, {params});
}

// 设置-主体信息-银行列表
export function getBankList(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/common/bank/list`, {params});
}

// 长连接
export function postMsgBind(params = {}) {
  return axios.post(`${BASE_URL}/api/msg/bind`, params);
}

// 长连接-已读
export function postMsgReadAction(params = {}) {
  return axios.post(`${BASE_URL}/api/msg/readAction`, params);
}

// 长连接-消息列表
export function postMsgList(params = {}) {
  return axios.post(`${BASE_URL}/api/msg/list`, params);
}

// 设置-品类-内外贸
export function getBusinessModeGet(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/business/mode/get`, {params});
}

// 设置-品类-标准库
export function getBmsGoodsList(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/goods/list`, {params});
}

// 设置-品类-标签库
export function getBmsGoodsTagList(params = {}) {
  return axios.get(`${BASE_URL}/api/bms/goods/tag/list`, {params});
}

// 内贸-客户列表
export function postDtOutBuyerList(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/dt/out/buyer/list`, params);
}

// 内贸-仓库列表
export function postDtOutStoreroomList(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/dt/out/storeroom/list`, params);
}

// 内贸-商品列表
export function postDtOutGoodsList(params = {}) {
  return axios.post(`${BASE_URL}/api/wo/dt/out/goods/list`, params);
}

// 付汇统计
export function postStatRemittance(params = {}) {
  return axios.post(`${BASE_URL}/api/stat/remittance`, params);
}

// 资金计划
export function postStatFundingplan(params = {}) {
  return axios.post(`${BASE_URL}/api/stat/funding_plan`, params);
}

// 外贸冻品数据统计
export function getDocumentation(params = {}) {
  return axios.get(`${BASE_URL}/api/stat/ftfp/documentation`, {params});
}

// 外贸冻品数据统计-导出
export function getFtfpExport(params = {}) {
  return axios.get(`${BASE_URL}/api/stat/ftfp/export`, {params});
}

// 用户管理-删除
export function postAdminDel(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/manager/admin/del`, params);
}

// 用户管理-授权
export function postAdminAuthorizedSignature(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/manager/admin/authorized_signature`, params);
}

// 用户管理-离职
export function postAdminDepart(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/manager/admin/depart`, params);
}

// 用户管理-发送电子签
export function postAdminQiyuesuo(params = {}) {
  return axios.post(`${BASE_URL}/api/qiyuesuo/ChannelCreateFlowByFiles`, params);
}

// 用户管理-获取url
export function postAdminUrl(params = {}) {
  return axios.post(`${BASE_URL}/api/qiyuesuo/getUrl`, params);
}

// 用户管理-获取Ticket
export function postAdminTicket(params = {}) {
  return axios.post(`${BASE_URL}/api/qiyuesuo/getTicket`, params);
}
// 供货商收款银行
export function postSupplierBankInfo(params = {}) {
  return axios.post(`${BASE_URL}/api/bms/supplier/bank/info`, params);
}
// 电子签
export function postChangeStatus(params = {}) {
  return axios.post(`${BASE_URL}/api/qiyuesuo/changeStatus`, params);
}
// 运营大屏
export function getBiStatistics(params = {}) {
  return axios.get(`${BASE_URL}/api/bi/statistics`, {params});
}