import { BASE } from './index';
import axios from './http';

const pi = {
    //冻品**********
    //采购订单列表
    orderpurchaselist: params => axios.get(`${BASE.PRO}/api/bs/order/purchase/list`,  {params} ),
    //采购订单列表下拉框模糊查询
    purchaseselect: params => axios.get(`${BASE.PRO}/api/bs/order/purchase/select`,  {params} ),
    //获取付款相关数据
    buyergetpayment: params => axios.get(`${BASE.PRO}/api/crm/buyer/get/payment`,  {params} ),
    //采购订单详情
    orderpurchaseinfo: params => axios.get(`${BASE.PRO}/api/bs/order/purchase/info`,  {params} ),
    //获取产地
    goodsgetorigin: params => axios.get(`${BASE.PRO}/api/bms/goods/get/origin`,  {params} ),
    //获取清关公司港口下拉
    clearanceselectport: params => axios.get(`${BASE.PRO}/api/bms/clearance/select/port`,  {params} ),
    //采购订单列表-作废
    orderpurchasecancel: params => axios.post(`${BASE.PRO}/api/bs/order/purchase/cancel`,  params ),
    //pi审批列表
    approvalprepaidlist: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/list`,  params ),
    //采购订单列表-添加
    orderpurchasesave: params => axios.post(`${BASE.PRO}/api/bs/order/purchase/save`,  params ),
    //采购订单列表-添加
    orderpurchaseconfirm: params => axios.post(`${BASE.PRO}/api/bs/order/purchase/confirm`,  params ),
    //pi发起审批详情
    approvalprepaidinfo: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/info`,  params ),
    //pi发起审批动作
    approvalprepaidinitiate: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/initiate`,  params ),
    //pi撤销审批
    approvalprepaidrevoke: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/revoke`,  params ),
    //pi重新发起审批
    approvalprepaidresubmit: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/resubmit`,  params ),
    //pi作废审批
    approvalprepaidcancel: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/cancel`,  params ),
    //pi审批列表进入审批详情
    approvalprepaidgetInfoByNum: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/getInfoByNum`,  params ),
    //pi审批详情修改附件和附言附件附言
    approvalprepaidupdateFile: params => axios.post(`${BASE.PRO}/api/bs/approval/prepaid/updateFile`,  params ),
   //车厘子*************************
    //采购订单列表
    cherrypurchaselist: params => axios.get(`${BASE.PRO}/api/bs/order/cherry/purchase/list`,  {params} ),
    //采购订单详情
    cherrypurchaseinfo: params => axios.get(`${BASE.PRO}/api/bs/order/cherry/purchase/info`,  {params} ),
  //采购订单列表-作废
  cherrypurchasecancel: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/purchase/cancel`,  params ),
  //采购订单列表-保存
  cherrypurchasesave: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/purchase/save`,  params ),
  //采购订单列表-确认
  cherrypurchaseconfirm: params => axios.post(`${BASE.PRO}/api/bs/order/cherry/purchase/confirm`,  params ),
  //提前还款-通过
  repaymentPass: params => axios.post(`${BASE.PRO}/api/wo/repayment/pass`,  params ),
  //提前还款-驳回
  repaymentReject: params => axios.post(`${BASE.PRO}/api/wo/repayment/reject`,  params ),
    

};

export default pi;
