import store from '@/store'
import router from '../router'
import { BASE } from '@/api/index';
import {
  postCommonExport,
} from "@/api/workOrder";
import common from '@/public/index.js'//公共函数方法引入
import { Message } from 'element-ui';
export default {
    //清除缓存信息
    cleanUp() {
        sessionStorage.setItem("token", '');
        sessionStorage.setItem("unread_num", '');
        sessionStorage.setItem("userInfo", JSON.stringify({}));
        sessionStorage.setItem("tagsList", []);
        store.commit("clearTags", []);
        store.commit("addmenuchildren", []);
        store.commit("addjump_path", "");
        store.commit("addconst", "");
        store.commit("updateToken", "");
        store.commit("updateUnread", "");
    },
    //链接返回数据处理
    getQueryParams(url) {
        // console.log(url)
        // 创建一个新的URL对象
        const urlObj = new URL(url);
        // 使用URLSearchParams解析查询参数
        const params = new URLSearchParams(urlObj.search);
        // 创建一个空对象来存储键值对
        const queryParams = {};

        // 遍历所有查询参数
        for (const [key, value] of params) {
            queryParams[key] = value;
        }

        return queryParams;
    },
    //表格选中数据提示语句
    choosemessage(data, type) {
        //    type 1,选中一条最多 2，选中多条
        if (data.length == 0) {
            Message.error({
                message: "请至少选中一条数据",
                type: "error",
            });
        } else if (data.length > 1 && type == 1) {
            Message.error({
                message: "只能选中一条数据进行编辑",
                type: "error",
            });
        } else {
            return true;
        }

    },
    //返回数组对象某个标识字段的下标
    itemIndex(data, name, value) {
        const index = data.findIndex(item => item[name] === value);
        if (index !== -1) {
            return index
        } else {
            return false
        }

    },
    //导出公共方法
    exportfile(url, ids) {
        window.open(
            BASE.PRO + `${url}?ids=${ids}&Authorization=${sessionStorage.getItem("token")}`,
            "_blank"
        );
    },
    //循环获取下拉框数据id对应的其他值
    selectlabe(data, id, name, lable) {
        // console.log(data, id, name, lable)
        let labers = ''
        data.forEach(el => {
            // console.log(el, el[name],id)
            if (el[name] == id) {
                labers = el[lable]
            }
        });
        return labers
    },
    //表格标头颜色同意
    tableHeaderColor() {
        return 'background-color: #F9F9F9;color:#333333;'
    },
    //获取当前时间
    time(date) {
        var y = date.getFullYear() + '';
        var m = date.getMonth() + 1 + '';
        m = m < 10 ? ('0' + m) : m + '';
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d + '';
        return y + m + d;
    },
    //下载
    downLoad(name) {
        window.open(name, '_blank')
    },
    //下载数组格式单条
    downLoadlist(name) {
        // console.log(name)
        // 方案1 -- 调用window.open在浏览器打开下载
        window.open(name.url, '_blank')
    },
    //预览
    seeckcikFile(url) {
        window.open('http://39.105.24.98:8012/onlinePreview?url=' + encodeURIComponent(Base64.encode(url)), "_blank");
    },
    // 保留五位小数，不足补零
    filterNumber(data, val) {
        // console.log('fiternumber',val);
        let res = Number(data || 0);
        let defaultData = '0', zero = ''
        if (val) {
            for (let i = 0; i < +val; i++) {
                zero += '0'
            }
            defaultData = defaultData.concat('.' + zero)
            
        } else {
            defaultData = '0.00000'
        }
        
        return res ? res.toFixed(val ? val : 5) : defaultData;
    },
    //比对数组值是否一样
    areAllValuesEqual(arr) {
        return arr.every(function (value, index, array) {
            return value === array[0];
        });
    },
    //给郭媛的计算欠我10块钱
    quotient(Totalnumber, length) {
        let num = (Totalnumber / length).toFixed(2)
        let lennum = (Totalnumber * 10000000000000 - ((length - 1) * (num * 100) / 100) * 10000000000000) / 10000000000000
        let obj = {
            num: num,
            lennum: lennum.toFixed(2)
        }
        return obj
    },
    //数字转大写
 
    toChineseNumber(num) {
        const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        return chineseNumbers[num];
    },
 
    convertToChineseNumber(num) {
        const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const units = ['', '十', '百', '千', '万'];
        let str = num.toString();
        let chineseStr = '';
 
        for (let i = 0; i < str.length; i++) {
            const numChar = parseInt(str[i]);
            const unitChar = units[str.length - 1 - i] || '';
            chineseStr += (numChar === 0 ? '' : chineseNumbers[numChar] + unitChar);
        }
 
        return chineseStr.replace(/^一十/, '十');
    },
    //审批进度跳转
    handleClick(e) {
        const router1 = router.resolve({
            path: "/print",
            query: {
                entry_id: e,
                // name: "domesticFinalDetail",
                // ...this.query,
            },
        });
        window.open(router1.href, "_blank");
    },
    //审批进度跳转
    YYD() {
        const now = new Date();
        const year = now.getFullYear(); // 获取当前年份，如2022
        const month = now.getMonth() + 1; // 获取当前月份，范围为0-11，需要加1
        const day = now.getDate(); // 获取当前日期，范围为1-31
        const hours = now.getHours(); // 获取当前小时数，范围为0-23
        const minutes = now.getMinutes(); // 获取当前分钟数，范围为0-59
        const seconds = now.getSeconds(); // 获取当前秒数，范围为0-59
        const milliseconds = now.getMilliseconds(); // 获取当前毫秒数，范围为0-999
        const currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return currentTime
    },
     //  判断数是否为整数
    isInteger(val) {
        val = Number(val || 0)
        return Math.floor(val) === val
    },
    /**
     * 将小数转换为整数，返回一个对象：整数和倍数，如3.14=>>314，倍数为100
     * @param val：小数
     * @returns {object}
     * {times:100,num:314}
     */
    toInteger(val) {
        let ret = { times: 1, num: 0 }
        if (this.isInteger(val)) {
            ret.num = val
            return ret
        }
        let str = val + ''
        let dotpos = str.indexOf('.')
        let len = str.substring(dotpos + 1).length
        let times = Math.pow(10, len)
        let intNum = val.toString().replace('.', '')
        ret.times = times
        ret.num = intNum
        return ret
    },
    /**
     * 将小数转换为整数,进行加减乘除
     * @param a和b为进行计算的数，op为运算方法：加：add，减：sub，乘：mult，除：divide
     * @returns 运算结果
     */
    operation(a, b, op) {
        let obj1 = this.toInteger(a), obj2 = this.toInteger(b)
        let num1 = Number(obj1.num), num2 = Number(obj2.num)
        let times1 = obj1.times, times2 = obj2.times
        let max = times1 > times2 ? times1 : times2
        let result = null
        switch (op) {
            case 'add':
                if (times1 === times2) { //小数位相同
                    result = num1 + num2
                } else if (times1 > times2) {
                    result = num1 + num2 * (times1 / times2)
                } else {
                    result = num1 * (times2 / times1) + num2
                }
                return result / max
            case 'sub':
                if (times1 === times2) {
                    result = num1 - num2
                } else if (times1 > times2) {
                    result = num1 - num2 * (times1 / times2)
                } else {
                    result = num1 * (times2 / times1) - num2
                }
                return result / max
            case 'mult':
                result = (num1 * num2) / (times1 * times2)
                return result
            case 'divide':
                result = (num1 / num2) * (times2 / times1)
                return result
        }
    },
     // 下载账单
    async createdFile(obj,val,type,fileName) {
      Message.warning({
        message: "正在生成文件，请耐心等待",
        duration: 9999
      });
      try {
        let { url } = await postCommonExport(obj);
        Message.success("已生成文件");
          if (val == "down" || !val) {
              if (type == 'spec') {
                common.download(url,fileName);
              } else {
                common.downLoad(url);
              }
          } else {
          common.seeckcikFile(url);
        }
      } catch (err) {
        Message.error("文件生成失败");
        console.log("postCommonExport err", err);
      } finally {
        setTimeout(() => {
          Message.closeAll();
        }, 1000);
      }
    },
    download(url1, fileName) {
        if (fileName.indexOf('.') != -1) {
           fileName = fileName.replace(/\./g,"_")
        }
        console.log(fileName);
      let self = this;
      const url = url1;
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function() {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
}
