import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO;

// 客商-定向协议-详情
export function getProtocolInfo(params = {}) {
  return axios.get(`${BASE_URL}/api/crm/buyer/directional/protocol/info`, {params});
}

// 客商-定向协议-新增
export function postProtocolSave(params = {}) {
  return axios.post(`${BASE_URL}/api/crm/buyer/directional/protocol/save`, params);
}