import { BASE } from './index';
import axios from './http';

const bs = {
     //港口自提初始化详情
     pickupbaseinfo: params => axios.get(`${BASE.PRO}/api/wo/pickup/base/info`,  {params} ),
     //港口自提详情
     pickuporderinfo: params => axios.post(`${BASE.PRO}/api/wo/settlement/base/info`,  params ),
     //入库库房列表
     storeroomlist: params => axios.get(`${BASE.PRO}/api/bms/storeroom/filter`,  {params} ),
     //港口自提详情
     settlementinfo: params => axios.get(`${BASE.PRO}/api/wo/settlement/info`,  {params} ),
     //仓储要求
     requirementlist: params => axios.get(`${BASE.PRO}/api/wms/requirement/storage`,  {params} ),
     //工单列表
     bswolist: params => axios.get(`${BASE.PRO}/api/wo/list`,  {params} ),
     //港口自提添加、编辑
     pickupordersave: params => axios.post(`${BASE.PRO}/api/wo/pickup/order/save`,  params ),
     //结算模板下拉框
     templatelist: params => axios.get(`${BASE.PRO}/api/wo/settlement/template/list`,  {params} ),
     //入库工单查详情
     putinfo: params => axios.post(`${BASE.PRO}/api/wo/put/info`,  params ),
     //保存入库工单信息 （修改时多传put_id）
     putsave: params => axios.post(`${BASE.PRO}/api/wo/put/save`,  params ),
     //保存入库工单发送
     putsend: params => axios.post(`${BASE.PRO}/api/wo/put/send`,  params ),
     //获取入库工单详情
     putget: params => axios.post(`${BASE.PRO}/api/wo/put/get`,  params ),
     //作废工单接口
     putvoid: params => axios.post(`${BASE.PRO}/api/wo/put/void`,  params ),
     //完税列表变更接口
     taxesalter: params => axios.post(`${BASE.PRO}/api/bs/order/paid/taxes/alter`,  params ),
     //上传回执
     putreceipt: params => axios.post(`${BASE.PRO}/api/wo/put/receipt`,  params ),
     //港口自提发送
     wopickupsend: params => axios.post(`${BASE.PRO}/api/wo/pickup/send`,  params ),
     //确定出库
     putreceipt: params => axios.post(`${BASE.PRO}/api/wms/put/receipt`,  params ),
     //确定出库详情接口
     woputget: params => axios.post(`${BASE.PRO}/api/wo/put/get`,  params ),
     //确定出库详情接口
     woputinfo: params => axios.post(`${BASE.PRO}/api/wms/put/info`,  params ),
     //发起出库工单详情接口
     wooutinfo: params => axios.post(`${BASE.PRO}/api/wo/out/info`,  params ),
     //发起出库工单保存完成详情接口
     wooutget: params => axios.post(`${BASE.PRO}/api/wo/out/get`,  params ),
     //发起出库工单保存完成详情接口
     wmsoutinfo: params => axios.post(`${BASE.PRO}/api/wms/out/info`,  params ),
     //确定出库
     wooutreceipt: params => axios.post(`${BASE.PRO}/api/wms/out/receipt`,  params ),
     //确定出库发送
     wooutsend: params => axios.post(`${BASE.PRO}/api/wo/out/send`,  params ),
     //发起出库工单保存接口
     wooutsave: params => axios.post(`${BASE.PRO}/api/wo/out/save`,  params ),
     //获取收付款台账信息
     paymentinfo: params => axios.post(`${BASE.PRO}/api/wo/settlement/payment/info`,  params ),
     //获取收付款台账信息
     paymentdetail: params => axios.post(`${BASE.PRO}/api/wo/center/get/expense/detail`,  params ),
     //完税订单列表接口
     orderpaidtaxes: params => axios.get(`${BASE.PRO}/api/bs/order/paid/taxes/get`,  {params} ),
     //完税订单列表发工单强制校验
     taxescheck: params => axios.get(`${BASE.PRO}/api/bs/order/paid/taxes/check`,  {params} ),
     //仓储入库保存
     putsaveDetail: params => axios.post(`${BASE.PRO}/api/wms/put/save/detail`,  params),
   
   
};

export default bs;
