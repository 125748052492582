import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//name要保证和path值一样是为了实现tab缓存
const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login.vue')
  },
   // 
   {
    path: '/print',//审批进度
    name: 'print',
    meta: {
      title: '审批进度',
    },
    component: () => import('@/views/print/index.vue')
  },
   {
    path: '/print2',//审批详情
    name: 'print2',
    meta: {
      title: '审批详情',
    },
    component: () => import('@/views/print/index2.vue')
  },
   
  {
    path: '/',
    name: 'publicHome',
    redirect: '/index',
    component: () => import('@/views/publicMenu/index.vue'),
    children: [
      {
        path: '/index',//首页
        name: 'index',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/index.vue')
      },
      {
        path: '/printlist',//审批列表
        name: 'printlist',
        meta: {
          title: '审批列表',
        },
        component: () => import('@/views/print/printlist.vue')
      },
      {
        path: '/Messagelist',//消息列表
        name: 'Messagelist',
        meta: {
          title: '消息列表',
        },
        component: () => import('@/views/message/Messagelist.vue')
      },
      {
        path: '/CustomerSupplierlist',//客商列表
        name: 'CustomerSupplierlist',
        meta: {
          title: '客商列表',
        },
        component: () => import('@/views/CustomerSupplier/list.vue')
      },
      {
        path: '/addsupplier',//客户信息
        name: 'addsupplier',
        meta: {
          title: '客户信息',
        },
        component: () => import('@/views/CustomerSupplier/addsupplier.vue')
      },
      {
        path: '/Admissionterms',//准入条款
        name: 'Admissionterms',
        meta: {
          title: '准入条款',
        },
        component: () => import('@/views/CustomerSupplier/Admissionterms.vue')
      },
      {
        path: '/Admissiontermsinfo',//准入条款添加
        name: 'Admissiontermsinfo',
        meta: {
          title: '准入条款添加',
        },
        component: () => import('@/views/CustomerSupplier/Admissiontermsinfo.vue')
      },
      {
        path: '/Admissiontermssetinfo',//准入条款详情
        name: 'Admissiontermssetinfo',
        meta: {
          title: '准入条款详情',
        },
        component: () => import('@/views/CustomerSupplier/Admissiontermssetinfo.vue')
      },
      {
        path: '/cooperateadd',//准入条款详情添加合作模式
        name: 'cooperateadd',
        meta: {
          title: '准入条款详情添加合作模式',
        },
        component: () => import('@/views/CustomerSupplier/cooperateadd.vue')
      },
      {
        path: '/CustomerSupplierapprove',//审批管理
        name: 'CustomerSupplierapprove',
        meta: {
          title: '客商-审批管理',
        },
        component: () => import('@/views/CustomerSupplier/approve.vue')
      },


      //设置-权限
      {
        path: '/role',//角色管理
        name: 'role',
        meta: {
          title: '角色管理',
        },
        component: () => import('@/views/setUp/authority/role.vue')
      },
      {
        path: '/department',//部门管理
        name: 'department',
        meta: {
          title: '部门管理',
        },
        component: () => import('@/views/setUp/authority/department.vue')
      },
      {
        path: '/user',//用户管理
        name: 'user',
        meta: {
          title: '用户管理',
        },
        component: () => import('@/views/setUp/authority/user.vue')
      },
      //跟单
      {
        path: '/documentaryfutures',//期货工作台
        name: 'documentaryfutures',
        meta: {
          title: '期货工作台',
        },
        component: () => import('@/views/documentary/documentaryfutures.vue')
      },
      {
        path: '/pIitem',//采购制单管理
        name: 'pIitem',
        meta: {
          title: '采购制单管理',
        },
        component: () => import('@/views/documentary/components/pIitem.vue')
      },
      {
        path: '/pIitemWorkOrderTable',//采购工单列表
        name: 'pIitemWorkOrderTable',
        meta: {
          title: '采购工单列表',
        },
        component: () => import('@/views/documentary/components/pIitemWorkOrderTable.vue')
      },
      {
        path: '/pIitemApprovalTable',//采购审批列表
        name: 'pIitemApprovalTable',
        meta: {
          title: '采购审批列表',
        },
        component: () => import('@/views/documentary/components/pIitemApprovalTable.vue')
      },
      {
        path: '/documentaryfuturesinfo',//采购订单详情
        name: 'documentaryfuturesinfo',
        meta: {
          title: '采购订单详情',
        },
        component: () => import('@/views/documentary/documentaryfuturesinfo.vue')
      },
      {
        path: '/purchaseinfo',//采购订单详情
        name: 'purchaseinfo',
        meta: {
          title: '车厘子-采购订单详情',
        },
        component: () => import('@/views/documentary/cherry/purchaseinfo.vue')
      },
      {
        path: '/cherryCIinfo',//CI单
        name: 'cherryCIinfo',
        meta: {
          title: '车厘子-CI单',
        },
        component: () => import('@/views/documentary/cherry/cherryCIinfo.vue')
      },
      {
        path: '/cherryTailnfo',//尾款单
        name: 'cherryTailnfo',
        meta: {
          title: '车厘子-尾款单',
        },
        component: () => import('@/views/documentary/cherry/cherryTailnfo.vue')
      },
      {
        path: '/cherryQGinfo',//CI单
        name: 'cherryQGinfo',
        meta: {
          title: '车厘子-清报关',
        },
        component: () => import('@/views/documentary/cherry/cherryQGinfo.vue')
      },
      {
        path: '/cherryPiApproval',//预付款付款审批
        name: 'cherryPiApproval',
        meta: {
          title: '车厘子-接单审批',
        },
        component: () => import('@/views/documentary/cherry/cherryPiApproval.vue')
      },
      {
        path: '/domPiApproval',//预付款付款审批
        name: 'domPiApproval',
        meta: {
          title: '内贸接单审批',
        },
        component: () => import('@/views/documentary/domesticDc/domPiApproval.vue')
      },
      {
        path: '/cherrySkApproval',//预付款付款审批
        name: 'cherrySkApproval',
        meta: {
          title: '车厘子-收首款审批',
        },
        component: () => import('@/views/documentary/cherry/cherrySkApproval.vue')
      },
      {
        path: '/domFirstApproval',//内贸-收付首款审批
        name: 'domFirstApproval',
        meta: {
          title: '内贸收首款审批',
        },
        component: () => import('@/views/documentary/domesticDc/domFirstApproval.vue')
      },
      {
        path: '/cherryWkApproval',//预付款付款审批
        name: 'cherryWkApproval',
        meta: {
          title: '车厘子-收尾款审批',
        },
        component: () => import('@/views/documentary/cherry/cherryWkApproval.vue')
      },
      {
        path: '/PiApproval',//预付款付款审批
        name: 'PiApproval',
        meta: {
          title: '预付款付款审批',
        },
        component: () => import('@/views/documentary/components/PiApproval.vue')
      },
      {
        path: '/CiApproval',//付尾款审批
        name: 'CiApproval',
        meta: {
          title: '付尾款审批',
        },
        component: () => import('@/views/documentary/components/CiApproval.vue')
      },
      {
        path: '/skApproval',//首款审批
        name: 'skApproval',
        meta: {
          title: '首款审批',
        },
        component: () => import('@/views/documentary/spotGoods/skApproval.vue')
      },
      {
        path: '/CustomsitemApproval',//情报关审批详情
        name: 'CustomsitemApproval',
        meta: {
          title: '清报关审批详情',
        },
        component: () => import('@/views/documentary/components/CustomsitemApproval.vue')
      },
      {
        path: '/ciOrderCreateinfo',//采购审单
        name: 'ciOrderCreateinfo',
        meta: {
          title: '采购审单',
        },
        component: () => import('@/views/documentary/ciOrderCreateinfo.vue')
      },
      {
        path: '/Customsiteminfo',//清报关
        name: 'Customsiteminfo',
        meta: {
          title: '清报关',
        },
        component: () => import('@/views/documentary/Customsiteminfo.vue')
      },
      {
        path: '/documentaryspots',//现货工作台
        name: 'documentaryspots',
        meta: {
          title: '现货工作台',
        },
        component: () => import('@/views/documentary/documentaryspots.vue')
      },
      {
        path: '/purchaseitemfino',//采购单详情
        name: 'purchaseitemfino',
        meta: {
          title: '采购单详情',
        },
        component: () => import('@/views/documentary/spotGoods/purchaseinfo/purchaseitemfino.vue')
      },
      {
        path: '/dompurchaseitemfino',//采购单详情
        name: 'dompurchaseitemfino',
        meta: {
          title: '采购单详情',
        },
        component: () => import('@/views/documentary/domesticDc/purchaseinfo/purchaseitemfino.vue')
      },
      {
        path: '/salesdetails',//销售制单
        name: 'salesdetails',
        meta: {
          title: '销售制单',
        },
        component: () => import('@/views/documentary/spotGoods/salesinfo/salesdetails.vue')
      },
      {
        path: '/salesdetailscw',//内贸结算详情
        name: 'salesdetailscw',
        meta: {
          title: '内贸结算详情',
        },
        component: () => import('@/views/documentary/spotGoods/salesinfo/salesdetailscw.vue')
      },
      {
        path: '/nmdcsalesdetailscw',//内贸结算详情
        name: 'nmdcsalesdetailscw',
        meta: {
          title: '内贸结算详情',
        },
        component: () => import('@/views/documentary/domesticDc/salesinfo/salesdetailscw.vue')
      },
      {
        path: '/workinfo',//入库工单详情
        name: 'workinfo',
        meta: {
          title: '入库工单详情',
        },
        component: () => import('@/views/documentary/spotGoods/purchaseinfo/workinfo.vue')
      },
      {
        path: '/nmworkinfo',//入库工单详情
        name: 'nmworkinfo',
        meta: {
          title: '内贸代采入库工单',
        },
        component: () => import('@/views/documentary/domesticDc/purchaseinfo/workinfo.vue')
      },
      {
        path: '/workout',//出库工单详情
        name: 'workout',
        meta: {
          title: '出库工单详情',
        },
        component: () => import('@/views/documentary/spotGoods/purchaseinfo/workout.vue')
      },
      // {
      //   path: '/nmworkout',//出库工单详情
      //   name: 'nmworkout',
      //   meta: {
      //     title: '内贸代采出库工单',
      //   },
      //   component: () => import('@/views/documentary/domesticDc/purchaseinfo/workout.vue')
      // },
      {
        path: '/purchaseApproval',//现货采购审批管理
        name: 'purchaseApproval',
        meta: {
          title: '现货采购审批管理',
        },
        component: () => import('@/views/documentary/spotGoods/purchaseinfo/purchaseApproval.vue')
      },
      //完税订单
      {
        path: '/DutypaidordersList',//完税订单列表
        name: 'DutypaidordersList',
        meta: {
          title: '完税订单列表',
        },
        component: () => import('@/views/Dutypaidorders/DutypaidordersList.vue')
      },
      {
        path: '/DutypaidordersWorkList',//完税工单列表
        name: 'DutypaidordersWorkList',
        meta: {
          title: '完税工单列表',
        },
        component: () => import('@/views/Dutypaidorders/DutypaidordersWorkList.vue')
      },
      {
        path: '/DutypaidordersApprovalList',//完税审批列表
        name: 'DutypaidordersApprovalList',
        meta: {
          title: '完税审批列表',
        },
        component: () => import('@/views/Dutypaidorders/DutypaidordersApprovalList.vue')
      },
      {
        path: '/Portselfpickup',//入库工单
        name: 'Portselfpickup',
        meta: {
          title: '入库工单',
        },
        component: () => import('@/views/Dutypaidorders/Portselfpickup.vue')
      },
      {
        path: '/Warehousing',//港口自提工单
        name: 'Warehousing',
        meta: {
          title: '港口自提工单',
        },
        component: () => import('@/views/Dutypaidorders/Warehousing.vue')
      },

      //结算
      {
        path: '/settlementlist',//赎货结算列表
        name: 'settlementlist',
        meta: {
          title: '赎货结算列表',
        },
        component: () => import('@/views/settlement/settlementlist.vue')
      },
      {
        path: '/settlementinfo',//赎货结算列表
        name: 'settlementinfo',
        meta: {
          title: '赎货账单',
        },
        component: () => import('@/views/settlement/settlementinfo.vue')
      },
      {
        path: '/nmsettleinfo',//赎货结算列表
        name: 'nmsettleinfo',
        meta: {
          title: '赎货账单',
        },
        component: () => import('@/views/settlement/nmsettleinfo.vue')
      },
      //风控列表
      {
        path: '/risklist',//风险订单管理
        name: 'risklist',
        meta: {
          title: '风险订单管理',
        },
        component: () => import('@/views/risk/risklist.vue')
      },
      {
        path: '/warningPrice',//补报预警
        name: 'warningPrice',
        meta: {
          title: '补报预警',
        },
        component: () => import('@/views/risk/warningPrice.vue')
      },
     
      {
        path: '/riskciitemTable',//风控审单复核列表
        name: 'riskciitemTable',
        meta: {
          title: '风控审单复核列表',
        },
        component: () => import('@/views/risk/riskciitemTable.vue')
      },
      {
        path: '/riskciOrderCreateinfo',//复核采购审单
        name: 'riskciOrderCreateinfo',
        meta: {
          title: '复核采购审单',
        },
        component: () => import('@/views/risk/riskciOrderCreateinfo.vue')
      },
      {
        path: '/riskpurchaseinfo',//车厘子-风险订单详情
        name: 'riskpurchaseinfo',
        meta: {
          title: '车厘子-风险订单详情',
        },
        component: () => import('@/views/risk/riskpurchaseinfo.vue')
      },
      {
        path: '/dcriskinfo',//内贸代采-风险订单详情
        name: 'dcriskinfo',
        meta: {
          title: '内贸代采-风险订单详情',
        },
        component: () => import('@/views/risk/dcriskinfo.vue')
      },
      {
        path: '/riskinfo',//风控订单详情
        name: 'riskinfo',
        meta: {
          title: '风控订单详情',
        },
        component: () => import('@/views/risk/riskinfo.vue')
      },
      {
        path: '/nmriskinfo',//风控订单详情
        name: 'nmriskinfo',
        meta: {
          title: '风控订单详情',
        },
        component: () => import('@/views/risk/nmriskinfo.vue')
      },

      //设置-信息配置
      {
        path: '/category',//品类管理
        name: 'category',
        meta: {
          title: '品类标准库',
        },
        component: () => import('@/views/setUp/datainfo/category/category.vue')
      },
      {
        path: '/price',//品类价格
        name: 'price',
        meta: {
          title: '品类标签库',
        },
        component: () => import('@/views/setUp/datainfo/category/price.vue')
      },
      {
        path: '/label',//品类标签
        name: 'label',
        meta: {
          title: '品类价格跟踪',
        },
        component: () => import('@/views/setUp/datainfo/category/label.vue')
      },
      {
        path: '/categoryFollow',//价格跟踪
        name: 'categoryFollow',
        meta: {
          title: '价格跟踪',
        },
        component: () => import('@/views/setUp/datainfo/category/categoryFollow.vue')
      },
      {
        path: '/CustomsClearanceCompany',//清关公司管理
        name: 'CustomsClearanceCompany',
        meta: {
          title: '清关公司管理',
        },
        component: () => import('@/views/setUp/datainfo/CustomsClearanceCompany.vue')
      },
      {
        path: '/SubjectInformation',//主体信息管理
        name: 'SubjectInformation',
        meta: {
          title: '主体信息管理',
        },
        component: () => import('@/views/setUp/datainfo/SubjectInformation.vue')
      },
      {
        path: '/supplier',//供货商管理
        name: 'supplier',
        meta: {
          title: '供货商管理',
        },
        component: () => import('@/views/setUp/datainfo/supplier.vue')
      },
      {
        path: '/Warehouse',//库房管理
        name: 'Warehouse',
        meta: {
          title: '库房管理',
        },
        component: () => import('@/views/setUp/datainfo/Warehouse.vue')
      },
      {
        path: '/storageSearch',//库存查询
        name: 'storageSearch',
        meta: {
          title: '库存查询',
        },
        component: () => import('@/views/storage/outstorageSearch.vue')
      },
      //设置-配置类
      {
        path: '/model',//业务模型配置
        name: 'model',
        meta: {
          title: '业务模型配置',
        },
        component: () => import('@/views/setUp/configuration/model.vue')
      },
      {
        path: '/futures',//业务配置期货
        name: 'futures',
        meta: {
          title: '业务配置期货',
        },
        component: () => import('@/views/setUp/configuration/futures.vue')
      },
      {
        path: '/Spotgoods',//业务配置现货
        name: 'Spotgoods',
        meta: {
          title: '业务配置现货',
        },
        component: () => import('@/views/setUp/configuration/Spotgoods.vue')
      },
      //协作
      {
        path: '/Approval',//审批列表
        name: 'Approval',
        meta: {
          title: '审批列表',
        },
        component: () => import('@/views/cooperation/Approval/Approval.vue')
      },
      {
        path: '/signature',//电子签
        name: 'signature',
        meta: {
          title: '电子签',
        },
        component: () => import('@/views/cooperation/signature.vue')
      },
      {
        path: '/Approvalinfo',//审批列表详情流
        name: 'Approvalinfo',
        meta: {
          title: '审批流详情',
        },
        component: () => import('@/views/cooperation/Approval/Approvalinfo.vue')
      },
      {
        path: '/hwyqApproval',//货物延期审批
        name: 'hwyqApproval',
        meta: {
          title: '货物延期审批',
        },
        component: () => import('@/views/documentary/spotGoods/hwyqApproval.vue')
      },
     

      //装修
      {
        path: '/configurationlist',//装修页面列表
        name: 'configurationlist',
        meta: {
          title: '装修列表',
        },
        component: () => import('@/views/configuration/list.vue')
      },
      {
        path: '/configuration',//装修页面
        name: 'configuration',
        meta: {
          title: '装修页面',
        },
        component: () => import('@/views/configuration/index.vue')
      },
      {
        path: '/supplierDetail',//供货商详情
        name: 'supplierDetail',
        meta: {
          title: '供货商详情',
        },
        component: () => import('@/views/setUp/datainfo/supplierDetail.vue')
      },
      {
        path: '/FixAgreement',//定向协议
        name: 'FixAgreement',
        meta: {
          title: '定向协议',
        },
        component: () => import('@/views/CustomerSupplier/FixAgreement.vue')
      },
      {
        path: '/workOrderManage',//工单管理
        name: 'workOrderManage',
        meta: {
          title: '工单管理',
        },
        component: () => import('@/views/index/workOrderManage.vue')
      },
      // 工单
      {
        path: '/earnestMoney',//应收保证金
        name: 'earnestMoney',
        meta: {
          title: '应收保证金',
        },
        component: () => import('@/views/workOrder/pi/earnestMoney.vue')
      },
      {
        path: '/prepayMoney',//预付货款
        name: 'prepayMoney',
        meta: {
          title: '预付货款',
        },
        component: () => import('@/views/workOrder/pi/prepayMoney.vue')
      },
      {
        path: '/firstMoney',//付首款
        name: 'firstMoney',
        meta: {
          title: '付首款',
        },
        component: () => import('@/views/workOrder/pi/firstMoney.vue')
      },
      {
        path: '/sell-lastMoney',//付尾款
        name: 'sell-lastMoney',
        meta: {
          title: '付尾款',
        },
        component: () => import('@/views/workOrder/ci/sell-lastMoney.vue')
      },
      {
        path: '/sellContract',//销售合同
        name: 'sellContract',
        meta: {
          title: '销售合同',
        },
        component: () => import('@/views/workOrder/clearanceCompany/sellContract.vue')
      },
      {
        path: '/repairEarnestMoney',//应补保证金
        name: 'repairEarnestMoney',
        meta: {
          title: '收尾款补保证金',
        },
        component: () => import('@/views/workOrder/ci/repairEarnestMoney.vue')
      },
      {
        path: '/lastMoney',//应付尾款
        name: 'lastMoney',
        meta: {
          title: '应付尾款',
        },
        component: () => import('@/views/workOrder/ci/lastMoney.vue')
      },
      {
        path: '/receiveFinal',//收尾款
        name: 'finalMoney',
        meta: {
          title: '收尾款',
        },
        component: () => import('@/views/workOrder/ci/finalMoney.vue')
      },
      {
        path: '/receiptPayFirstMoney',//收付首款
        name: 'receiptPayFirstMoney',
        meta: {
          title: '收付首款',
        },
        component: () => import('@/views/workOrder/pi/receiptPayFirstMoney.vue')
      },
      {
        path: '/domRecePayFirstMoney',//收付首款
        name: 'domRecePayFirstMoney',
        meta: {
          title: '内贸-收付首款',
        },
        component: () => import('@/views/workOrder/pi/domRecePayFirstMoney.vue')
      },
      {
        path: '/receiptPayLastMoney',//收付尾款
        name: 'receiptPayLastMoney',
        meta: {
          title: '收付尾款',
        },
        component: () => import('@/views/workOrder/ci/receiptPayLastMoney.vue')
      },
      {
        path: '/receiptRepairLastMoney',//收补尾款
        name: 'receiptRepairLastMoney',
        meta: {
          title: '收补尾款',
        },
        component: () => import('@/views/workOrder/ci/receiptRepairLastMoney.vue')
      },
      {
        path: '/leadMoney',//支用申领
        name: 'leadMoney',
        meta: {
          title: '支用申领',
        },
        component: () => import('@/views/workOrder/ci/leadMoney.vue')
      },
      {
        path: '/leadMoneyList',//支用申领管理
        name: 'leadMoneyList',
        meta: {
          title: '支用申领管理',
        },
        component: () => import('@/views/workOrder/leadMoneyList.vue')
      },
      {
        path: '/leadMoneyDetail',//申领完成确认
        name: 'leadMoneyDetail',
        meta: {
          title: '申领完成确认',
        },
        component: () => import('@/views/workOrder/leadMoneyDetail.vue')
      },
      {
        path: '/normalTax',//一般付税
        name: 'normalTax',
        meta: {
          title: '一般付税',
        },
        component: () => import('@/views/workOrder/clearanceCompany/normalTax.vue')
      },
      {
        path: '/addTax',//汇总增税
        name: 'addTax',
        meta: {
          title: '汇总增税',
        },
        component: () => import('@/views/workOrder/clearanceCompany/addTax.vue')
      },
      {
        path: '/taxMoney',//收税款
        name: 'taxMoney',
        meta: {
          title: '收税款',
        },
        component: () => import('@/views/workOrder/clearanceCompany/taxMoney.vue')
      },
      {
        path: '/cherryTax',//车里子收税款
        name: 'cherryTax',
        meta: {
          title: '收税款',
        },
        component: () => import('@/views/workOrder/clearanceCompany/cherryTax.vue')
      },
      {
        path: '/repairTax',//补税款
        name: 'repairTax',
        meta: {
          title: '冻品补税款',
        },
        component: () => import('@/views/workOrder/clearanceCompany/repairTax.vue')
      },
      {
        path: '/cherryRepairTax',//车厘子补税款
        name: 'cherryRepairTax',
        meta: {
          title: '车厘子补税款',
        },
        component: () => import('@/views/workOrder/clearanceCompany/cherryRepairTax.vue')
      },
      // 财务
      {
        path: '/paymentMoney',//收付款工作台
        name: 'paymentMoney',
        meta: {
          title: '收付款工作台',
        },
        component: () => import('@/views/finance/paymentMoney.vue')
      },
      {
        path: '/confirmFirst',//付款确认
        name: 'confirmFirst',
        meta: {
          title: '付款确认',
        },
        component: () => import('@/views/finance/confirmPay/firstMoney.vue')
      },
      {
        path: '/confirmLast',//付款确认
        name: 'confirmLast',
        meta: {
          title: '付款确认',
        },
        component: () => import('@/views/finance/confirmPay/lastMoney.vue')
      },
      {
        path: '/confirmCherryRepair',//补税款付款确认
        name: 'confirmCherryRepair',
        meta: {
          title: '车厘子补税款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/confirmCherryRepair.vue')
      },
      {
        path: '/confirmRepairTax',//补税款付款确认
        name: 'confirmRepairTax',
        meta: {
          title: '补税款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/confirmRepairTax.vue')
      },
      {
        path: '/confirmEarnest',//付款确认
        name: 'confirmEarnest',
        meta: {
          title: '预付货款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/earnestMoney.vue')
      },
      {
        path: '/confirmPay',//付款确认
        name: 'confirmPay',
        meta: {
          title: '付尾款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/payMoney.vue')
      },
      {
        path: '/confirmNormal',//付款确认
        name: 'confirmNormal',
        meta: {
          title: '一般付税付款确认',
        },
        component: () => import('@/views/finance/confirmPay/normalTax.vue')
      },
      {
        path: '/confirmAddtax',//付款确认
        name: 'confirmAddtax',
        meta: {
          title: '汇总增税付款确认',
        },
        component: () => import('@/views/finance/confirmPay/addTax.vue')
      },
      {
        path: '/confirmCherryFirst',//付款确认
        name: 'cherryFirstMoney',
        meta: {
          title: '车厘子首款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/cherryFirstMoney.vue')
      },
      {
        path: '/domFirstMoney',//付款确认
        name: 'domFirstMoney',
        meta: {
          title: '内贸首款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/domFirstMoney.vue')
      },
      {
        path: '/confirmCherryLast',//付款确认
        name: 'cherryLastMoney',
        meta: {
          title: '车厘子尾款付款确认',
        },
        component: () => import('@/views/finance/confirmPay/cherryLastMoney.vue')
      },
      {
        path: '/SettleManage',//结算单管理
        name: 'SettleManage',
        meta: {
          title: '结算单管理',
        },
        component: () => import('@/views/finance/SettleManage.vue')
      },
      {
        path: '/settlePort',//港口自提
        name: 'settlePort',
        meta: {
          title: '港口自提',
        },
        component: () => import('@/views/finance/settlePort.vue')
      },
      {
        path: '/settleRetired',//库房赎货
        name: 'settleRetired',
        meta: {
          title: '库房赎货',
        },
        component: () => import('@/views/finance/settleRetired.vue')
      },
      {
        path: '/nmsettleRetired',//库房赎货
        name: 'nmsettleRetired',
        meta: {
          title: '库房赎货',
        },
        component: () => import('@/views/finance/nmsettleRetired.vue')
      },
      {
        path: '/proofManage',//凭证管理
        name: 'proofManage',
        meta: {
          title: '凭证管理',
        },
        component: () => import('@/views/finance/proofManage.vue')
      },
      {
        path: '/proofDetail',//凭证详情
        name: 'proofDetail',
        meta: {
          title: '凭证详情',
        },
        component: () => import('@/views/finance/proofDetail.vue')
      },
      {
        path: '/invoiceWorkbench1',//开票工作台
        name: 'invoiceWorkbench',
        meta: {
          title: '开票工作台',
        },
        component: () => import('@/views/finance/invoiceWorkbench.vue')
      },
      {
        path: '/invoiceDetail',//开票详情
        name: 'invoiceDetail',
        meta: {
          title: '开票详情',
        },
        component: () => import('@/views/finance/invoiceDetail.vue')
      },
      {
        path: '/invoiceSearch',//开票信息管理
        name: 'invoiceSearch',
        meta: {
          title: '开票信息管理',
        },
        component: () => import('@/views/finance/invoiceSearch.vue')
      },
     
      // 仓储
      {
        path: '/storage',//出入库工作台
        name: 'storage',
        meta: {
          title: '出入库工作台',
        },
        component: () => import('@/views/storage/storage.vue')
      },
      {
        path: '/outStorage',//出库
        name: 'outStorage',
        meta: {
          title: '出库',
        },
        component: () => import('@/views/storage/outStorage.vue')
      },
      {
        path: '/inStorage',//入库
        name: 'inStorage',
        meta: {
          title: '入库',
        },
        component: () => import('@/views/storage/inStorage.vue')
      },
      {
        path: '/storageDetail',//仓储费用
        name: 'storageDetail',
        meta: {
          title: '仓储费用',
        },
        component: () => import('@/views/storage/storageDetail.vue')
      },
      {
        path: '/nmstorageDetail',//入库工单
        name: 'nmstorageDetail',
        meta: {
          title: '入库工单',
        },
        component: () => import('@/views/storage/nmstorageDetail.vue')
      },
      {
        path: '/nmDomStorageDetail',//入库工单
        name: 'nmDomStorageDetail',
        meta: {
          title: '内贸入库工单',
        },
        component: () => import('@/views/documentary/domesticDc/purchaseinfo/workinfo.vue')
      },
      {
        path: '/nmworkout',//入库工单
        name: 'nmoutDomStorage',
        meta: {
          title: '内贸出库工单',
        },
        component: () => import('@/views/storage/nmoutDomStorage.vue')
      },
      {
        path: '/nmoutStorage',//确定出库
        name: 'nmoutStorage',
        meta: {
          title: '确定出库',
        },
        component: () => import('@/views/storage/nmoutStorage.vue')
      },
      // 开票
      {
        path: '/clarifyList',//提清订单列表
        name: 'clarifyList',
        meta: {
          title: '提清订单列表',
        },
        component: () => import('@/views/invoiceApply/clarifyList.vue')
      },
      {
        path: '/approveDetail',//审批管理
        name: 'approveDetail',
        meta: {
          title: '审批管理',
        },
        component: () => import('@/views/invoiceApply/approveDetail.vue')
      },
      {
        path: '/approveManage',//审批管理
        name: 'approveManage',
        meta: {
          title: '审批管理',
        },
        component: () => import('@/views/invoiceApply/approveManage.vue')
      },
      {
        path: '/approveShow',//审批管理
        name: 'approveShow',
        meta: {
          title: '审批管理',
        },
        component: () => import('@/views/invoiceApply/approveShow.vue')
      },
      {
        path: '/globalList',//全局跟单
        name: 'globalList',
        meta: {
          title: '全局跟单',
        },
        component: () => import('@/views/index/globalList.vue')
      },
      {
        path: '/paymentList',//付汇统计
        name: 'paymentList',
        meta: {
          title: '付汇统计',
        },
        component: () => import('@/views/statement/paymentList.vue')
      },
      {
        path: '/moneyPlan',//资金计划
        name: 'moneyPlan',
        meta: {
          title: '资金计划',
        },
        component: () => import('@/views/statement/moneyPlan.vue')
      },
      {
        path: '/receiptList',//接单统计
        name: 'receiptList',
        meta: {
          title: '接单统计',
        },
        component: () => import('@/views/statement/receiptList.vue')
      },
      {
        path: '/repayList',//提前还款
        name: 'repayList',
        meta: {
          title: '提前还款',
        },
        component: () => import('@/views/workOrder/repayList.vue')
      },
      {
        path: '/repayDetail',//提前还款工单
        name: 'repayDetail',
        meta: {
          title: '提前还款工单',
        },
        component: () => import('@/views/workOrder/repayDetail.vue')
      },
      {
        path: '/fruitSettleList',//水果结算列表
        name: 'fruitSettleList',
        meta: {
          title: '水果结算列表',
        },
        component: () => import('@/views/settlement/fruitSettleList.vue')
      },
      {
        path: '/balanceReceivable',//应收差额汇总表
        name: 'balanceReceivable',
        meta: {
          title: '应收差额汇总表',
        },
        component: () => import('@/views/settlement/balanceReceivable.vue')
      },
      {
        path: '/refundMoney',//退款工单
        name: 'refundMoney',
        meta: {
          title: '退款工单',
        },
        component: () => import('@/views/workOrder/clearanceCompany/refundMoney.vue')
      },
      {
        path: '/balanceMoney',//差额结算工单
        name: 'balanceMoney',
        meta: {
          title: '差额结算工单',
        },
        component: () => import('@/views/workOrder/clearanceCompany/balanceMoney.vue')
      },
      {
        path: '/cherryRefundMoney',//车厘子退款工单
        name: 'cherryRefundMoney',
        meta: {
          title: '车厘子退款工单确认',
        },
        component: () => import('@/views/finance/confirmPay/cherryRefundMoney.vue')
      },
      {
        path: '/paysettle',//付汇结算
        name: 'paysettle',
        meta: {
          title: '付汇结算',
        },
        component: () => import('@/views/settlement/paysettle.vue')
      },
      {
        path: '/entrancesettle',//入场结算
        name: 'entrancesettle',
        meta: {
          title: '入场结算',
        },
        component: () => import('@/views/settlement/entrancesettle.vue')
      },
      {
        path: '/taxsettle',//税金结算
        name: 'taxsettle',
        meta: {
          title: '税金结算',
        },
        component: () => import('@/views/settlement/taxsettle.vue')
      },
      {
        path: '/frozenStatistics',//外贸冻品数据统计
        name: 'frozenStatistics',
        meta: {
          title: '外贸冻品数据统计',
        },
        component: () => import('@/views/statement/frozenStatistics.vue')
      },
      {
        path: '/repayManage',//提前还款管理
        name: 'repayManage',
        meta: {
          title: '提前还款管理',
        },
        component: () => import('@/views/settlement/repayManage.vue')
      },
      {
        path: '/ticketInfo',//开票信息
        name: 'ticketInfo',
        meta: {
          title: '开票信息',
        },
        component: () => import('@/views/CustomerSupplier/ticketInfo.vue')
      },
      {
        path: '/invoiceApproval',//开票审批列表
        name: 'invoiceApproval',
        meta: {
          title: '开票审批列表',
        },
        component: () => import('@/views/invoiceApply/invoiceApproval.vue')
      },
      {
        path: '/invoiceApprovalDetail',//开票审批详情
        name: 'invoiceApprovalDetail',
        meta: {
          title: '开票审批详情',
        },
        component: () => import('@/views/invoiceApply/invoiceApprovalDetail.vue')
      },
      {
        path: '/invoiceList',//开票申请
        name: 'invoiceList',
        meta: {
          title: '开票申请',
        },
        component: () => import('@/views/invoiceApply/invoiceList.vue')
      },
      {
        path: '/invoiceSaleUpdate',//销项票
        name: 'invoiceSaleUpdate',
        meta: {
          title: '销项票详情',
        },
        component: () => import('@/views/invoiceApply/invoiceSaleUpdate.vue')
      },
      {
        path: '/invoiceSaleitem',//销项票
        name: 'invoiceSaleitem',
        meta: {
          title: '销项票',
        },
        component: () => import('@/views/invoiceApply/invoiceSaleitem.vue')
      },
      {
        path: '/summaryList',//流程耗时汇总统计
        name: 'summaryList',
        meta: {
          title: '流程耗时汇总统计',
        },
        component: () => import('@/views/statement/summaryList.vue')
      },
      {
        path: '/statPersonList',//流程个人耗时统计
        name: 'statPersonList',
        meta: {
          title: '流程个人耗时统计',
        },
        component: () => import('@/views/statement/statPersonList.vue')
      },
      {
        path: '/screenData',//运营大屏
        name: 'screenData',
        meta: {
          title: '运营大屏',
        },
        component: () => import('@/views/statement/screenData.vue')
      },
    ]
  },
]
const router = new VueRouter({
  routes
})

// 解决路由重复跳转错误
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => { })
};


Vue.use(VueRouter)
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;

  if (!sessionStorage.getItem("token") && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});
export default router
